<template>
  <v-footer
    absolute
    app
    class="elevation-12"
    height="88"
  >
    <v-container
      class="py-0"
      fluid
    >
      <v-row
        align="center"
        class="fill-height"
        justify="start"
      >
        <div>
          <v-img max-height="55px" contain src="/static/zsm-it-logo-dark-sm.png" />
        </div>
        <v-spacer class="d-none d-md-block" />
        <router-link class="caption pr-md-12" to="Impressum">
          Impressum
        </router-link>
        <div class="caption pl-3 pr-md-12">
          ZSM-IT UG
        </div>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    computed: {
      categories () {
        return []
      }
    }
  }
</script>
